import request from '@/utils/request'


// 查询外联类型列表
export function listType(query) {
    return request({
        url: '/platform/type/list',
        method: 'get',
        params: query
    })
}

// 查询外联类型分页
export function pageType(query) {
    return request({
        url: '/platform/type/page',
        method: 'get',
        params: query
    })
}

// 查询外联类型详细
export function getType(data) {
    return request({
        url: '/platform/type/detail',
        method: 'get',
        params: data
    })
}

// 新增外联类型
export function addType(data) {
    return request({
        url: '/platform/type/add',
        method: 'post',
        data: data
    })
}

// 修改外联类型
export function updateType(data) {
    return request({
        url: '/platform/type/edit',
        method: 'post',
        data: data
    })
}

// 删除外联类型
export function delType(data) {
    return request({
        url: '/platform/type/delete',
        method: 'post',
        data: data
    })
}